import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header/Header";
import Hero from "../components/Heading/Hero";
import PageHeading from "../components/Heading/PageHeading";
import Container from "../components/Container/Container";
import { StaticImage } from "gatsby-plugin-image";
import Footer from "../components/Footer/Footer";
import "./about.scss";

export default function About() {
  return (
    <main className="about">
      <Helmet title="Yvette Russell | About the Author" defer={false} />
      <Header />
      <Hero>
        <PageHeading>
          <h2>
            About <strong>Yvette Russell</strong>
          </h2>
          <p>
            Yvette Russell is an author and illustrator based in Vancouver,
            Canada where she gets inspiration from the gloom and rain. When not
            writing nightmare-fuel, she’s probably drawing or napping with her
            cat.
          </p>
        </PageHeading>
      </Hero>
      <Container layout="horz" name="page" type="background-decor">
        <div className="about-column">
          <p>
            <strong>Okay, enough with the third-person.</strong>
          </p>
          <p>
            Telling stories is something I've done my entire life, usually in
            the form of comics or illustrations. In fact, my life dream is to
            one day publish a graphic novel. But, after years of honing my
            illustration skills, I realized my writing needed more work.
          </p>
          <p>
            As a lifelong artist, I know that skill only improves with practice.
            So what better way to learn than to throw oneself into the deep end?
          </p>
          <p>
            That deep end came in the form of a long and lonely November.
            November marks the start of National Novel Writing Month, a wild
            event that encourages participants to write an entire novel in a
            month. If it sounds insane, that's because it is, <em>especially</em> if
            you've never written anything long-form before.
          </p>
          <p>
            But I'm nothing if not stubborn. And so, at the end of that harried
            month, I had my first novel.
          </p>
          <p>
            While my first attempt was rough and flawed, writing it seemed to
            spark something in me. I couldn't stop writing. I started publishing
            online. And since then, I've written many more novels, amassed
            millions of reads online, and even become a Wattpad Star.
          </p>
          <p>Funny where life takes you.</p>
          <p>And yes, I am still working on that graphic novel.</p>
        </div>
        <div className="about-portrait">
          <StaticImage
            className="about-portrait__image"
            src="../images/portrait.png"
            alt="Yvette Russell"
            quality="100"
          />
          <caption className="about-portrait__caption u-mar-top-md">
            One day I’ll get professional photos taken but for now have this
            creepy self portrait I did. Boo.
          </caption>
        </div>
      </Container>
      <Footer />
    </main>
  );
}
